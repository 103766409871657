import React, { useCallback, useEffect, useState } from 'react';
import MicrosoftLogin from 'react-microsoft-login';
import { ApiPostCallAuthKey } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import Cookies from 'js-cookie';
import { HideLoder, RemoveAllCookies, ShowLoder } from '../../JS/Common';
const clientId = process.env.REACT_APP_MicrosoftClientId;
const redirectUri = process.env.REACT_APP_Redirecturi;

export const MicrosoftLoginForm = (props) => {
    const [alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasRegistered, setHasRegistered] = useState(false);
    useEffect(() => {
        RemoveAllCookies();
    }, []);
    const handleLoginSuccess = useCallback(async (error, authData, msalInstance) => {
        if (hasRegistered) return;
        try {
            if (authData) {
                setLoading(true)
                var accessToken = authData.accessToken;
                var raw = JSON.stringify({
                    name: authData.account.name,
                    email: authData.account.userName,
                    googleId: null,
                    microsoftId: authData.account.accountIdentifier,
                    accessToken: accessToken,
                    flag: 2
                });
                setHasRegistered(true);
                await ApiPostCallAuthKey("/register", raw).then((result) => {
                    if (result == undefined || result == "") {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={'Login Failed!'} />);
                    } else {
                        setLoading(false)
                        const responseRs = JSON.parse(result);
                        if (responseRs.status == "success") {
                            const expires = 1;
                            Cookies.set('CsvUserId', responseRs.msg.id, { expires: expires, secure: true, sameSite: 'Strict' });
                            Cookies.set('schoolid', responseRs.msg.school_id, { expires: expires, secure: true, sameSite: 'Strict' });
                            Cookies.set('ciphertext', responseRs.msg.remember_token, { expires: expires, secure: true, sameSite: 'Strict' });
                            if (responseRs.schoolDetails != null) {
                                Cookies.set('Schoolnumber', responseRs.schoolDetails.school_number, { expires: expires, secure: true, sameSite: 'Strict' });
                            }
                            Cookies.set('ShippingType', responseRs?.schoolDetails?.shipping_type, { expires: expires, secure: true, sameSite: 'Strict' });
                            props.alertcolor("green");
                            props.alertmsg(`Authentication successfull! Redirecting to the home page...`);
                            setTimeout(() => {
                                if (responseRs.msg.access_type == 5 || responseRs.msg.access_type == 6) {
                                    window.location = '/school-tickets';
                                } else {
                                    window.location.href = '/';
                                }
                            }, 1500);
                        }
                        else {
                            props.alertcolor("red");
                            props.alertmsg(responseRs.response);
                        }
                    }
                });
            } else {
                console.log('Login failure. Error:', error);
            }
        } catch (error) {
            console.error('Error fetching Microsoft SignInVendor:', error);
        }
        finally {
            setLoading(false)
        }
    });

    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            {alerts}
            <div style={{ padding: '10px 75px 10px 75px', fontSize: '13px', border: '1px solid #e2e3e4', borderRadius: '16px', backgroundColor: '#f9fafb', fontFamily: '"Poppins", sans-serif', cursor: 'pointer' }}>
                <MicrosoftLogin
                    clientId={clientId}
                    redirectUri={redirectUri}
                    authCallback={handleLoginSuccess}
                    prompt="select_account"
                    buttonTheme="light"
                    className="CustomMicrosoftBtn"
                    forceRedirect={true}
                >
                    <img src="/images/MicrosoftBtn.svg" alt="Microsoft" className="img-fluid pe-3" />
                    <span>Sign in with Microsoft</span>
                </MicrosoftLogin>
            </div>
        </>
    );
};




